import { HOSTED_ZONE_DOMAIN, KEYVAULT_HOSTED_ZONE_DOMAIN, TEST_EU_UNIQUE_IDENTIFIER } from '../../../core/constants';

const uniqueId = TEST_EU_UNIQUE_IDENTIFIER;

export const environment = {
  production: false,
  stage: 'test',
  uniqueIdentifier: uniqueId,
  api: `https://app.${uniqueId}.${HOSTED_ZONE_DOMAIN}`,
  keyVault: `https://${uniqueId}.${KEYVAULT_HOSTED_ZONE_DOMAIN}`,
  live: `https://live.${uniqueId}.${HOSTED_ZONE_DOMAIN}`,
  websocket: `wss://wss-app.${uniqueId}.${HOSTED_ZONE_DOMAIN}`,
};
